// Remove our preload class from the body, preventing early CSS transitions
import '@utils/preload';

// CSS needs to be imported here as it's treated as a module
import '@/scss/style.scss';

// ================================================
// All third-party, globally-required libraries should be loaded here
// ================================================

// Support for lazyload images (and bg images)
import 'lazysizes';
import 'lazysizes/plugins/unveilhooks/ls.unveilhooks';
import 'lazysizes/plugins/bgset/ls.bgset';

// Flickity - please use `data-flickity` options to configure
import 'flickity';
import 'flickity-bg-lazyload';

// An excellent, responsive and lightweight lightbox
import 'spotlight.js';

// Simple & lightweight library to create smooth & beautiful animations when you scroll.
import lax from 'lax.js';

// ================================================
// SUI Components
// ================================================

import MobileNav from '@sgroup/mobile-nav';
import ClickToggle from '@sgroup/click-toggle';

// ================================================
// Custome Modules
// ================================================
import './modules/header';
import './modules/alert';


// ================================================
// Setup Vue3 - Use only if this project needs Vue!
// ================================================
import { createApp } from 'vue';


// Import all globally-available components here. Be responsible please.
import {
    Disclosure,
    DisclosureButton,
    DisclosurePanel,
    TabGroup,
    TabList,
    Tab,
    TabPanels,
    TabPanel,
} from '@headlessui/vue';

createApp({
    delimiters: ['${', '}'],

    components: {
        HuiDisclosure: Disclosure,
        HuiDisclosureButton: DisclosureButton,
        HuiDisclosurePanel: DisclosurePanel,
        HuiTabGroup: TabGroup,
        HuiTabList: TabList,
        HuiTab: Tab,
        HuiTabPanels: TabPanels,
        HuiTabPanel: TabPanel,
    },

    data() {
        return {
            navActive: false,
        };
    },

    mounted() {
        // Because we're using Vue, anything that interacts with the DOM should go here.

        // A minimal responsive menu with just vanilla JS
        new MobileNav();

        // A search box for header. Handles clicking away
        new ClickToggle({
            openSelector: '[data-search-open]',
            activeClass: 'search-open',
            clickAwaySelector: '[data-header-search-form]',
        });

        window.lax = {
            presets: lax.presets,
        };

        lax.init();

        lax.addDriver('scrollY', () => {
            return window.scrollY;
        });

        // Figures in Hero
        lax.addElements('.figure-right', {
            scrollY: {
                translateY: [
                    ['elInY', 'elCenterY', 'elOutY'],
                    // [0, 200],
                    {
                        400: [0, 100, 200],
                        768: [0, 100, 200],
                        1024: [0, 100, 200],
                        1280: [0, 100, 200],
                    },
                ],
            },
        });

        lax.addElements('.figure-left', {
            scrollY: {
                translateY: [
                    ['elInY', 'elOutY'],
                    {
                        400: ['(elHeight/100) * 65', 0],
                        768: ['(elHeight/100) * 65', 0],
                        1024: ['(elHeight/100) * 65', 0],
                        1280: ['(elHeight/100) * 65', 0],
                    },
                ],
            },
        });

        lax.addElements('.figure-bottom', {
            scrollY: {
                translateX: [
                    ['elInY', 'elCenterY', 'elOutY'],
                    // [0, -100, -200],
                    {
                        400: [0, -100, -200],
                        768: [0, -100, -200],
                        1024: [0, -100, -200],
                        1280: [0, -100, -200],
                    },
                ],
            },
        });

        // Hero logo
        lax.addElements('.large-logo', {
            scrollY: {
                translateY: [
                    [0, 'screenHeight'], [0, -100],
                ],
            },
        });

        // Figures in Media With Text
        lax.addElements('.media-right', {
            scrollY: {
                translateY: [
                    ['elInY', 'elCenterY', 'elOutY'],
                    {
                        400: [-100, -50, 0],
                        768: [-100, -50, 0],
                        1024: [-100, -50, 0],
                        1280: [-100, -50, 0],
                    },
                    {
                        inertia: -1,
                    },
                ],
            },
        });

        lax.addElements('.media-left', {
            scrollY: {
                translateY: [
                    ['elInY', 'elCenterY', 'elOutY'],
                    {
                        400: [100, 50, 0],
                        768: [100, 50, 0],
                        1024: [100, 50, 0],
                        1280: [100, 50, 0],
                    },
                    {
                        inertia: -1,
                    },
                ],
            },
        });

        lax.addElements('.media-bottom', {
            scrollY: {
                translateX: [
                    ['elInY', 'elCenterY-(screenHeight *.25)', 'elOutY'],
                    {
                        400: [50, 25, 0],
                        768: [50, 25, 0],
                        1024: [100, 50, 0],
                        1280: [100, 50, 0],
                    },
                    {
                        inertia: -1,
                    },
                ],
            },
        });


        // Figures in Full Media With Text
        lax.addElements('.full-media-left', {
            scrollY: {
                translateY: [
                    ['elInY', 'elCenterY', 'elOutY'],
                    {
                        400: [50, 25, 0],
                        768: [50, 25, 0],
                        1024: [100, 50, 0],
                        1280: [100, 50, 0],
                    },
                ],
            },
        });

        lax.addElements('.full-media-bottom', {
            scrollY: {
                translateX: [
                    ['elInY', 'elCenterY', 'elOutY'],
                    {
                        400: [50, 25, 0],
                        768: [50, 25, 0],
                        1024: [100, 50, 0],
                        1280: [100, 50, 0],
                    },
                ],
            },
        });

        // Figures in page headers
        lax.addElements('.pageheader-figure-left', {
            scrollY: {
                translateY: [
                    ['elInY', 'elCenterY', 'elOutY'],
                    [0, 0, -100],
                ],
            },
        });

        lax.addElements('.pageheader-figure-bottom', {
            scrollY: {
                translateX: [
                    ['elInY', 'elCenterY', 'elOutY'],
                    [0, 0, -100],
                ],
            },
        });

        // Figures in page body
        lax.addElements('.pagebody-figure-left', {
            scrollY: {
                translateY: [
                    ['elInY', 'elCenterY', 'elOutY'],
                    [100, 0, 0],
                ],
            },
        });
    },
}).mount('#app');
